import { HttpStatusCode } from '@solidjs/start';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';
import { Button, Container, Heading, HorizontalRule, Link, Page, Pagination, Picture, Section } from '@troon/ui';
import { createAsync, useParams } from '@solidjs/router';
import { For, Show, Suspense } from 'solid-js';
import { Link as HeadLink, Title } from '@solidjs/meta';
import { Icon } from '@troon/icons';
import { getConfigValue } from '../../../modules/config';
import { getDocuments } from '../../api/documents/[docType]';

dayjs.extend(utc);
dayjs.extend(tz);

export default function PressReleasePaginated() {
	const params = useParams<{ page?: string }>();
	const releases = createAsync(() => getDocuments('press-release', { page: parseInt(params.page ?? '1', 10) }), {
		deferStream: true,
	});

	return (
		<Container>
			<Page>
				<Title>
					Press Releases<Show when={params.page}>{(page) => ` | Page ${page()}`}</Show> | Troon
				</Title>
				<Show when={params.page === '1'}>
					<HeadLink rel="canonical" href="/press-releases" />
				</Show>
				<Section>
					<Heading as="h1">Press Releases</Heading>
					<div class="grid grid-cols-1 gap-8 md:grid-cols-2 xl:grid-cols-3">
						<Suspense>
							<For
								each={releases()?.items}
								fallback={
									<div class="flex flex-col gap-8">
										<HttpStatusCode code={404} />
										<p>No press releases found.</p>
										<Button class="w-fit" as={Link} href="/press-releases">
											<Icon name="arrow-left-md" />
											Back to Press Releases
										</Button>
									</div>
								}
							>
								{(release, i) => (
									<article class="group relative flex flex-col gap-2">
										<div class="overflow-hidden rounded">
											<Picture
												src={`${getConfigValue('IMAGE_HOST')}/digital/press-releases/${release.image}`}
												alt=""
												sizes={[[480, 270]]}
												class="size-full group-hover:scale-105 motion-safe:transition-transform motion-safe:duration-200"
												crop="face"
												loading={i() < 6 ? 'eager' : 'lazy'}
											/>
										</div>
										<Link
											href={`/press-releases/${release.slug}`}
											class="block text-lg font-semibold after:absolute after:inset-0"
										>
											{release.title}
										</Link>
										<time class="text-sm">{dayjs(release.date, 'America/Phoenix').format('MMMM D, YYYY')}</time>
									</article>
								)}
							</For>
						</Suspense>
					</div>
				</Section>

				<Suspense>
					<Show when={releases()?.items?.length}>
						<Show when={releases()}>
							{(data) => (
								<>
									<HorizontalRule />
									<Pagination
										currentPage={parseInt(params.page ?? '1', 10)}
										firstPage={1}
										lastPage={data()!.pages}
										url={(page) => `/press-releases/page/${page}`}
										nextTitle="Older"
										prevTitle="Newer"
									/>
								</>
							)}
						</Show>
					</Show>
				</Suspense>
			</Page>
		</Container>
	);
}
